@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/Gordita/GorditaLight.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/Gordita/GorditaRegular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/Gordita/GorditaMedium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/Gordita/GorditaBold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/Gordita/GorditaBlack.otf');
  font-weight: 900;
}

body {
  font-family: Gordita, 'Open Sans', 'Helvetica', 'sans-serif' !important;
  overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
body,
input {
  font-family: Gordita, 'Open Sans', 'Helvetica', 'sans-serif' !important;
  margin: 0;
  padding: 0;
  color: #313131;
}

/* Consistent accessibility across browsers */
input:focus {
  outline-style: none;
  /*box-shadow: 0 0 0 1px #000000;*/
}

.restaurant-selector-container .paragraph-small {
  /*font-size: 20px;*/
}

.error-box {
  background-color: #fbf6f6;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  color: #e02222;
  font-size: 18px;
}

main {
  padding: 0 !important;
}

.MuiFormHelperText-contained {
  font-family: Gordita, 'Open Sans', 'Helvetica', 'sans-serif' !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.MuiButtonLabel {
  padding-top: 2px;
  margin-bottom: -2px;
}
